const tabItems = [
  {
    title: "دیتاسنتر ابری",
    img: "/vpc.svg",
    img_deactive: "/vpc_d.svg",
    section_title: "تمام امکانات یک دیتاسنتر فیزیکی در دستان شماست",
    caption: `در این سرویس ابری ویژه، تمامی امکانات و ویژگی‌های دیتاسنتر فیزیکی در زیرساخت مستقل ابری در اختیار شما قرار می‌گیرد و از طریق داشبورد اختصاصی خود در «ابر زس» می‌توانید منابع‌تان را به صورت آنلاین مدیریت و بهینه کنید!
    شما می‌توانید با دیتاسنتر ابری «زس» از منابع محاسباتی، بدون نیاز به نصب، نگهداری و یا مراجعه حضوری، به صورت کاملاً مجازی و آنلاین استفاده کنید! و مهم‌تر از همه، در این سرویس ویژه، مدیریت شیوه‌ی پرداخت را خودتان انجام خواهید داد!
    دیتاسنتر ابری خود را با استفاده از معماری داده مــــــــــدرن ابر «زس» بسازید.`,
    contentImg: "/data center-c.svg",
    contentImg_alt: "داشبورد اپن استک در سرویس دیتاسنتر ابری ابر زس",
    link: "/vpc",
    alt: "آیکون دیتاسنتر ابری",
  },
  {
    title: "سرور ابری",
    img: "/vps.svg",
    img_deactive: "/vps_d.svg",
    section_title:
      "با سرور ابری «زس» کسب‌وکار خود را سریع و ساده گسترش دهید!    ",
    caption: `
    زمانی که نگران امنیت هستید! زمانی که وب‌سایت شما حجم بالای ترافیک را تجربه می‌کند و با کندی در حال اجراست! زمانی که با خطاهای سرور مواجه می‌شوید! و از همه مهم‌تر وقتی هزینه و بودجه زیادی برای سرورهای خود صرف می‌کنید، نشان‌دهنده این واقعیت است که زمان استفاده از «سرور ابری» برای کسب‌وکارتان فرا رسیده!
    وب‌سایتی که از سرور ابری استفاده می‌کند سریع‌تر اجرا می‌شود و چابک‌تر است. همچنین با هزینه یکسان، در سرورهای ابری منابع بیشتر و باکیفیت‌تری نسبت به سرورهای سنتی دریافت خواهید کرد!
    سرورهای ابری زس را می‌توانید در لحظه بسازید و بلافاصله از آن استفاده کنید.
    `,
    contentImg: "/Server-c.svg",
    contentImg_alt: "منوی داشبورد سرور ابری ابر زس",
    link: "/vps",
    alt: "آیکون سرور ابری",
  },
  {
    title: "پلتفرم ابری",
    img: "/paas.svg",
    section_title: "کد زدن و آزمون و خطا از شما، زیرساخت و پشتیبانی از ما!    ",
    img_deactive: "/paas_d.svg",
    caption: `در پلتفرم ابری «زس»، توسعه‌دهندگان هر زیرساختی را که برای ساختن یک برنامه نیاز دارند، از ما دریافت می‌کنند و برای توسعه محصولات و خدمات خود به این سرویس ویژه تکیه می‌کنند.
    PaaS توسعه اپلیکیشن‌ها را بسیار ساده‌تر می‌کند و از طریق هر اتصال اینترنتی قابل دسترس است. همچنین ساخت یک برنامه کامل را برای توسعه‌دهنده، به سادگی در قاب یک مرورگر وب ممکن می‌سازد.
    از آنجایی که محیط توسعه در PaaS در زیرساخت ابری ارائه می‌شود، توسعه‌دهندگان می‌توانند از هر کجای دنیا روی برنامه‌های مختلف کار کنند.
    `,
    contentImg: "/Platform-c.svg",
    contentImg_alt: "منوی داشبورد پلتفرم ابری ابر زس",
    link: "/paas",
    alt: "آیکون سرویس پلتفرم ابری",
  },
  {
    title: "سرویس های مدیریت شده",
    img: "/manageServices.svg",
    section_title: "پشتیبانی مداوم، منظم و مدیریت فعال زیرساخت‌های IT شما    ",
    img_deactive: "/manageServices_d.svg",
    caption: `
    فرقی نمی‌کند یک مدیر کسب‌وکار باشید یا یک متخصص IT، استفاده از خدمات مدیریت‌شده می‌تواند به طرز قابل توجهی کار شما را آسان‌تر کند. هنگامی که از سرویس‌های مدیریت‌شده ابر «زس» استفاده می‌کنید، مانند این است که متخصصان با تجربه، با مهارت‌های تخصصی متنوع و جدید را به تیم فناوری اطلاعات مجموعه خود اضافه کرده‌اید!
در این خدمت ویژه‌ی ابر «زس»، انعطاف‌پذیری عملیاتی در بخش فناوری اطلاعات خود را به مراتب افزایش دهید و به نیروهای IT خود اجازه دهید روی برنامه‌ها و اهداف استراتژیک و مهم‌تر تمرکز کنند.
`,
    contentImg: "/Managedservices-c.svg",
    contentImg_alt: "منوی داشبورد سرویس‌های مدیریت شده ابر زس",
    link: "/manageServices",
    alt: "آیکون سرویس های مدیریت شده ابر زس",
  },
  {
    title: "شبکه توزیع محتوا",
    section_title: "Content Delivery Network (CDN)    ",
    img: "/contentdistribution.svg",
    img_deactive: "/contentdistribution_d.svg",
    caption: `بهبود زمان بارگذاری وب‌سایت، کاهش هزینه‌های پهنای باند، افزایش در دسترس بودن، بهبود امنیت وب‌سایت و… از مهم‌ترین ویژگی‌های CDN یا شبکه توزیع محتوا است.
    CDN در هسته خود، شبکه‌ای از سرورهای توزیع شده جغرافیایی‌ست که با هدف ارائه محتوا با سرعت بالا، ارزان، قابل اعتماد و ایمن به هم مرتبط شده‌اند.
    این سرویس ابری به کاربران اجازه می‌دهد به یک مرکز داده نزدیکتر از لحاظ جغرافیایی متصل شوند و در نتیجه زمان سفر کمتر و خدماتی سریعتر را تجربه کنند!
    چنانچه خواهان وب‌سایتی چابک‌تر و ایمن‌تر باشید، می‌توانید شبکه توزیع محتوای ابر «زس» را انتخاب کنید!
    `,
    contentImg: "/Content distribution-c.svg",
    contentImg_alt: "منوی داشبورد شبکه توزیع محتوای ابر زس",
    link: "/cloudguard/cdn",
    alt: "آیکون سرویس ابری شبکه توزیع محتوا",
  },
  {
    title: "کلود گارد",
    img: "/security.svg",
    section_title: "دیوار امنیتی «زس» در برابر هر نوع خطری پشتیبان شماست!",
    img_deactive: "/security_d.svg",
    caption: `محافظت امنیتی «زس»، وب‌سایت‌ها، برنامه‌ها و کل شبکه‌های شما را ایمن می‌کند و در عین حال اطمینان می‌دهد که ترافیک طبیعی وب‌سایت شما به خطر نمی‌افتد.
    ابر «زس» با به کارگیری مجموعه ابزارهایی از جمله: DDoS Protection, WAF و… که با هم یک دفاع جامع در برابر طیف وسیعی از حملات ایجاد می‌کنند، از زیرساخت‌های شما محافظتی یکپارچه و ایمن به عمل می‌آورد.
    `,
    contentImg: "/security-c.svg",
    contentImg_alt: "منوی داشبورد محافظت ابر زس",
    link: "/cloudguard",
    alt: "آیکون سرویس محافظت ابری زس",
  },
];

const logos = [
  {
    url: "/images/new_customers/amirkabir.png",
    name: "دانشگاه صنعتی امیرکبیر",
  },
  {
    url: "/images/new_customers/azad.png",
    name: "دانشگاه آزاد اسلامی",
  },
  {
    url: "/images/new_customers/bankrefah.png",
    name: "بانک رفاه کارگران",
  },
  {
    url: "/images/new_customers/bankshahr.png",
    name: "بانک شهر",
  },
  {
    url: "/images/new_customers/barkat.png",
    name: "شرکت کارگزاری بیمه برکت",
  },
  {
    url: "/images/new_customers/bidbarg.png",
    name: "بیدبرگ",
  },
  {
    url: "/images/new_customers/digikala.png",
    name: "دیجی کالا",
  },
  {
    url: "/images/new_customers/eastcool.png",
    name: "ایستکول",
  },
  {
    url: "/images/new_customers/elmosanat.png",
    name: "دانشگاه علم و صنعت",
  },
  {
    url: "/images/new_customers/freezone.png",
    name: "دبیرخانه شورای عالی مناطق آزاد تجاری، صنعتی و ویژه اقتصادی",
  },
  {
    url: "/images/new_customers/HiMart.png",
    name: "هایمارت",
  },
  {
    url: "/images/new_customers/irisaco.png",
    name: "شرکت بین‌المللی مهندسی سیستم‌ها و اتوماسیون ها",
  },
  {
    url: "/images/new_customers/jameazad.png",
    name: "جامعه آزاد رایانش ابری ایران",
  },
  {
    url: "/images/new_customers/javid.png",
    name: "گروه جاوید",
  },
  {
    url: "/images/new_customers/khandevane.png",
    name: "خندوانه",
  },
  {
    url: "/images/new_customers/madiran.png",
    name: "مادیران",
  },
  {
    url: "/images/new_customers/mahran.png",
    name: "مهرام",
  },
  {
    url: "/images/new_customers/mashal.png",
    name: "مشعل",
  },
  {
    url: "/images/new_customers/meydon.png",
    name: "میدون",
  },
  {
    url: "/images/new_customers/mofid.png",
    name: "کارگزاری مفید",
  },
  {
    url: "/images/new_customers/netbarg.png",
    name: "نت‌برگ",
  },
  {
    url: "/images/new_customers/parskhazar.png",
    name: "پارس خزر",
  },
  {
    url: "/images/new_customers/parsonline.png",
    name: "پارس آنلاین",
  },
  {
    url: "/images/new_customers/pegah.png",
    name: "پگاه",
  },
  {
    url: "/images/new_customers/pejoheshgah.png",
    name: "پژوهشگاه هواشناسی",
  },
  {
    url: "/images/new_customers/samseir.png",
    name: "سام‌سیر",
  },
  {
    url: "/images/new_customers/tanzim.png",
    name: "سازمان تنظیم مقررات رسانه های صوت و تصویر ساترا",
  },
];

const customers = [
  {
    name: "mali",
    title: "خدمات مالی و بیمه",
    children: [
      {
        name: "dey",
        title: "بیمه دی",
        banner: "/HomePage-insurance.webp",
        logo: "/dey.png",
        description: `شرکت بیمه «دی» یکی از مجموعه‌های بیمه‌ای‌ست که به تحول دیجیتال و استفاده از تکنولوژی در صنعت بیمه اهمیت زیادی می‌دهد. کارشناسان فنی این مجموعه به قابلیت افزایش و کاهش منابع در لحظه و کنترل و بهینه‌سازی هزینه‌های IT فکر می‌کردند و تمایل داشتند وارد بازی سخت و پیچیده خرید و نگه‌داری دیتاسنترهای سنتی با دغدغه‌های تمام نشدنی‌اش نشوند و اینجا آغاز همکاری و همراهی بیمه «دی» و ابر «زس» برای راه‌اندازی سرویس ویژه «دیتاسنتر ابری» بود که نتیجه آن بهبود فرآیندهای IT و افزایش بهره‌وری این شرکت بود!`,
        banner_alt: "نظر شرکت خدمات مالی درباره ابر زس",
        banner_title: "نظر مشتری درباره خدمات ابر زس",
        logo_alt: "بیمه دی",
      },
    ],
  },
  {
    name: "inno&tech",
    title: "نوآوری و تکنولوژی",
    children: [
      {
        name: "diginext",
        title: "دیجی‌نکست",
        banner: "/HomePage-innovation.webp",
        logo: "/diginext.png",
        description: `دیجی‌نکست مرکز نوآوری و سرمایه‌گذاری گروه دیجی‌کالاست که به عنوان یک شتاب‌دهنده بزرگ به استارتاپ‌های زیادی برای خلق داستان موفقیت کمک می‌کند، «سرور ابری» و «دیتاسنتر ابری» دو محصول اختصاصی حوزه کلود توسط «زس» برای این مجموعه اجرا و پیاده‌سازی شد تا در لحظه بتواند با توجه به ویژگی مقیاس‌پذیری و انعطاف در افزایش و کاهش منابع، زیرساخت‌های مورد نیاز استارتاپ‌های خود را به آسانی از طریق داشبورد ابری «زس» مدیریت و بهینه کند.`,
        banner_alt: "نظر شرکت‌های نوآوری و تکنولوژی درباره ابر زس",
        banner_title: "نظر مشتری درباره خدمات ابر زس",
        logo_alt: "دیجی‌نکست",
      },
    ],
  },
  {
    name: "lms",
    title: "پلتفرم آموزش آنلاین",
    children: [
      {
        name: "e-seminar",
        title: "ایسمینار",
        banner: "/HomePage-elearning.webp",
        logo: "/esiminar.png",
        description: `قطعا یکی از اسامی نام‌آشنا برای ویدئوهای آموزشی و تعاملی و برگزاری وبینار در ایران، مجموعه «ایسمینار» است. حجم بسیار زیاد ویدئوها، سرعت آپلود و دانلود، اهمیت دسترس‌پذیری کاربران به محتواهای ویدئویی و قدرت پردازش بالا تنها بخشی از دغدغه‌ها و مسائل این مجموعه در زمان انتخاب ابر «زس» به عنوان اپراتور ابری بود که با خرید سرویس «دیتاسنتر» ابری «زس»، توانست به قسمت مهمی از چالش‌های زیرساختی خود پاسخی شایسته دهد.        `,
        banner_alt: "نظر پلتفرم‌های آموزش آنلاین درباره ابر زس",
        banner_title: "نظر مشتری درباره خدمات ابر زس",
        logo_alt: "ایسمینار",
      },
    ],
  },
  {
    name: "unis",
    title: "دانشگاه‌ها و مراکز آموزشی",
    children: [
      {
        name: "aiu",
        title: "دانشگاه آزاد اسلامی",
        banner: "/HomePage-universities.webp",
        logo: "/Azad.png",
        description: `برای مجموعه‌هایی که به دلیل استراتژی‌ها و نیازهای کسب‌وکاری خود در برهه‌هایی از زمان و به صورت موردی نیاز به منابع زیاد و زیرساخت‌های موقتی دارند، دانشگاه آزاد یک نمونه بارز است که به دلیل افزایش مقطعی بار ترافیکی وب‌سایت خود برای برگزاری آزمون …. نیاز به افزایش منابع خود را با همراهی ابر «زس» و از طریق سرویس «دیتاسنتر ابری» به راحتی پوشش داد.`,
        banner_alt: "نظر دانشگاه‌ها و مراکز آموزشی درباره ابر زس",
        banner_title: "نظر مشتری درباره خدمات ابر زس",
        logo_alt: "دانشگاه آزاد اسلامی",
      },
    ],
  },
  {
    name: "foods",
    title: "صنایع غذایی",
    children: [
      {
        name: "pegah",
        title: "شرکت صنایع شیر ایران (پگاه)",
        banner: "/HomePage-foods.webp",
        logo: "/pegah.png",
        description: `چه کسی در ایران هست که نام پگاه را نشنیده باشد و از وسعت تولیدات و گستردگی آن بی‌خبر باشد. طبیعتاً این حجم از ارائه خدمات و محصولات با تعداد زیادی نیروی کار و فرآیندهای داخلی پیچیده و عظیم اتفاق می‌‌افتد. یک تصمیم استراتژیک در بخش IT پگاه استفاده از سرورها و «دیتاسنتر ابری» به جای خرید، راه‌اندازی و نگهداری زیرساخت‌های سنتی به صورت جداگانه برای نمایندگی‌ها و شرکت‌های زیرمجموعه‌اش در هر استان کشور بود که این ماموریت به ابر «زس» واگذار شد تا با ارائه سرویس‌های ابری ویژه، مدیریت میل‌سرورها، اتوماسیون‌ها و فرآیند IT داخلی این مجموعه را روی زیرساخت یکپارچه و امن ابری ارائه دهد.`,
        banner_alt: "نظر صنایع غذایی درباره ابر زس",
        banner_title: "نظر مشتری درباره خدمات ابر زس",
        logo_alt: "پگاه",
      },
    ],
  },
  {
    name: "tolidis",
    title: "شرکت‌های تولیدی",
    children: [
      {
        name: "istcoll",
        title: "ایستکول",
        banner: "/HomePage-manufacturing-firms.webp",
        logo: "/istcoll.png",
        description: `شرکت با سابقه «تکران مبرد» که با نام تجاری «ایستکول» از سال 1356 تا به حال، فعالیتش در رسته لوازم خانگی و تولید انواع یخچال و… است، جلوتر از بسیاری از رقبای خودش را در این صنعت توانست با روند پیشرفت فناوری همگام شود و لزوم استفاده از سرویس‌های ابری را بپذیرد، این مجموعه نیز با اعتماد به ابر «زس»، درایوهای سازمانی خودش را به فضای ابری آورد و محافظت و پشتیبان‌گیری از اطلاعات خود را به دستان ابر «زس» سپرد.        `,
        banner_alt: "نظر شرکت‌های تولیدی درباره ابر زس",
        banner_title: "نظر مشتری درباره خدمات ابر زس",
        logo_alt: "ایستکول",
      },
    ],
  },
  {
    name: "banks",
    title: "بانک‌ها و موسسات مالی",
    children: [
      {
        name: "shahr",
        title: "گروه مالی شهر",
        banner: "/HomePage-banks.webp",
        logo: "/shahr.png",
        description: `هلدینگ «گروه مالی شهر» که به عنوان بازوی نیرومند «بانک شهر» شناخته می‌شود در حوزه خدمات مالی، سرمایه‌گذاری و شتاب‌دهی کسب‌و‌کارهای نوپا فعالیت می‌کند، این هلدینگ توانست برنامه استراتژیک فناوری اطلاعات خودش را همپای تکنولوژی ببیند و پا به جهان بی‌انتهای ابرها بگذارد!
        «دیتاسنتر ابری» یکی از سرویس‌های ویژه‌ای بود که به میزبانی ابر «زس» برای این مجموعه بزرگ راه‌اندازی و اجرا شد تا هلدینگ «گروه مالی شهر»، انعطاف‌پذیری، انطباق، قدرت پردازش و امنیت را در قلب مسیر توسعه خدمات خود تجربه کند.       `,
        banner_alt: "نظر بانک‌ها و موسسات مالی درباره ابر زس",
        banner_title: "نظر مشتری درباره خدمات ابر زس",
        logo_alt: "گروه مالی شهر",
      },
      {
        name: "refah",
        title: "بانک رفاه کارگران",
        banner: "/HomePage-banks.webp",
        logo: "/refah.png",
        description: `استفاده از پتانسیل بالای رایانش ابری برای هر بانک و مجموعه‌ای که در حوزه مالی و سرمایه‌گذاری فعالیت می‌کند کمتر از رقم خوردن یک معجزه در نحوه سرویس‌دهی به مشتریان و مدیریت بهینه منابع نیست! بانک رفاه کارگران نیز با بینشی مدرن، فضای توسعه زیرساخت‌ها و منابع IT خود روی «ابر» را وسیع دید و به سرویس‌های ابری خوش‌آمد گفت. ابر «زس» افتخار همراهی با بانک رفاه را برای پیاده‌سازی سرورهای ابری به عهده داشته و از جمله خدماتی که با استفاده از راهکارهای ابری «زس» به نمایش گذاشته شد، عرضه اولیه سهام در بورس توسط بانک رفاه بود.`,
        banner_alt: "نظر بانک‌ها و موسسات مالی درباره ابر زس",
        banner_title: "نظر مشتری درباره خدمات ابر زس",
        logo_alt: "بانک رفاه",
      },
      // {
      //   name: "etebar-it",
      //   title: "اعتبار آی‌تی",
      //   banner: "/banks.jpg",
      //   logo: "",
      //   description:`مجموعه صنعتی گروه «مپنا» یکی از بزرگترین مجریان و پیمانکاران عمرانی، نیروگاهی، پزشکی و… در کشور است. با توجه به وسعت فعالیت‌ها و حجم پروژه‌های مپنا، قاعدتاً این مجموعه با چالش‌های بسیاری در دپارتمان فناوری اطلاعات خود از جمله پراکندگی تمرکز مواجه است که راهکارهای ابری می‌تواند به تنهایی پاسخی درخور به موانع استفاده از زیرساخت‌های سنتی‌اش باشد. محصول ویژه «سرویس‌های مدیریت شده» و راه‌حل‌های ابری «زس» توانسته است جای خود را در پی‌ریزی تصمیمات تجاری و بخش IT این مجموعه باز کند تا فرآیندهای انطباق، انعطاف‌پذیری و مقیاس‌پذیری منابع IT دیگر برای‌شان دغدغه‌ای نباشد.        `
      // },
    ],
  },
  {
    name: "it",
    title: "فناوری اطلاعات",
    children: [
      {
        name: "parsOnline",
        title: "پارس آنلاین",
        banner: "/HomePage-IT.webp",
        logo: "/pars.png",
        description: `«ابر اختصاصی» نام یکی از پیچیده‌ترین و بزرگترین محصولات در بین سرویس‌های ابری‌ست که گروه شرکت‌های «پارس‌آنلاین» میزبانی این خدمت خاص را به ابر «زس» واگذار کرد. این مجموعه که خودش نیز یکی از قدرتمندترین ارائه‌دهندگان زیرساخت‌های فناوری اطلاعات در کشور است با دریافت خدمت ویژه راه‌اندازی ابر خصوصی به وسیله ابر «زس»، این امکان را دارد که خودش به میزان نامحدودی سرور ابری با منابع مدیریت شده توسط داشبورد ابری «زس» را به کاربرانش ارائه دهد. تیم «عملیات خلاق زس» در این پروژه خاص تمامی موارد طراحی و اجرایی فنی از جمله استقرار سیستم یکپارچه OSS و BSS را به عهده داشته است.        `,
        banner_alt: "نظر شرکت‌های فناوری اطلاعات درباره ابر زس",
        banner_title: "نظر مشتری درباره خدمات ابر زس",
        logo_alt: "پارس آنلاین",
      },
      {
        name: "irisa",
        title: "ایریسا",
        banner: "/HomePage-IT.webp",
        logo: "/IRISA.png",
        description:
          "شرکت بین‌المللی مهندسی سیستم‌ها و اتوماسیون با نام تجاری «ایریسا» که پیمانکار IT مجموعه‌های بزرگی از جمله: فولاد مبارکه اصفهان، مخابرات کل کشور، ذوب‌آهن اصفهان، بانک کشاورزی، شرکت پاکسان، شرکت ملی حفاری ایران و… است، مسئولیت تأمین زیرساخت‌های فناوری اطلاعات مشتریانش را به عهده دارد که به علت موانع رایج خریداری، راه‌اندازی، نگهداری، مقایس‌پذیری و توسعه در زیرساخت‌های سنتی IT، تصمیم به استفاده از زیرساخت‌های ابری «زس» را عملیاتی کرد و در حال حاضر با استفاده از پنل مدیریت منابع ابری «زس» می‌تواند در هر زمانی با توجه به نیاز کاربرانش افزایش یا کاهش منابع را خودش مدیریت کرده و چالش‌های مواجه با زیرساخت‌های سنتی را از فرآیندهای کاری خود حذف کند.        ",
        banner_alt: "نظر شرکت‌های فناوری اطلاعات درباره ابر زس",
        banner_title: "نظر مشتری درباره خدمات ابر زس",
        logo_alt: "ایریسا",
      },
    ],
  },
  {
    name: "medias",
    title: "رسانه‌ها",
    children: [
      {
        name: "khandevaneh",
        title: "خندوانه",
        banner: "/HomePage-media.webp",
        logo: "/khandevaneh.png",
        description: `مسابقه‌ای که روی ابرها برگزار شد!
        برنامه خندوانه در جریان برگزاری مسابقات استندآپ‌کمدی «خنداننده شو» نیاز به فرآیند رأی‌گیری از تماشاگرهایش داشت که طبیعتاً این ریسک محتمل بود تا در صورت استفاده از زیرساخت‌های سنتی خودش توانایی پاسخ‌دهی به حجم بالای ترافیک کاربرها را نداشته باشد و «سرور ابری» مطمئن‌ترین و بهترین راهکاری بود که به میزبانی ابر «زس» برای آن‌ها برنامه‌ریزی و راه‌اندازی شد تا بدون کوچکترین دغدغه‌ای پذیرای هر سطحی از استقبال کاربرهای‌شان باشند.`,
        banner_alt: "نظر رسانه‌ها درباره ابر زس",
        banner_title: "نظر مشتری درباره خدمات ابر زس",
        logo_alt: "خندوانه",
      },
    ],
  },
  {
    name: "agancies",
    title: "آژانس‌های خدماتی",
    children: [
      {
        name: "samsir",
        title: "سام‌سیر",
        banner: "/HomePage-agencies.webp",
        logo: "/Samseir.png",
        description: `آژانس هواپیمایی «سام‌ سِیر» که سامانه‌ای برای خرید آنلاین بلیط هواپیماست، مثل تمام فعالان این حوزه، کاملاً با چشم‌انداز زیبای روی ابرها آشنا بود تا اینکه برای توسعه زیرساخت‌های سنتی خودش نیز دست به اقدام هوشمندانه‌ای زد و آن‌ها را هم روی ابرها آورد!
        زیرساخت‌های ابری «زس» مسیر توسعه خدمات را برای این مجموعه کاملا هموار کرده و با مدیریت یکپارچه منابع ابری سام سیر، توانسته‌ایم فضای سرویس‌دهی آن‌ها را مجهز به قابلیت‌های «رایانش ابری» کنیم!`,
        banner_alt: "نظر آژانس‌های خدماتی درباره ابر زس",
        banner_title: "نظر مشتری درباره خدمات ابر زس",
        logo_alt: "سام‌سیر",
      },
    ],
  },
  {
    name: "indus-orgs",
    title: "سازمان‌های صنعتی",
    children: [
      {
        name: "mapna",
        title: "مپنا",
        banner: "/HomePage-industries.webp",
        logo: "/Mapna.png",
        description: `مجموعه صنعتی گروه «مپنا» یکی از بزرگترین مجریان و پیمانکاران عمرانی، نیروگاهی، پزشکی و… در کشور است. با توجه به وسعت فعالیت‌ها و حجم پروژه‌های مپنا، قاعدتاً این مجموعه با چالش‌های بسیاری در دپارتمان فناوری اطلاعات خود از جمله پراکندگی تمرکز مواجه است که راهکارهای ابری می‌تواند به تنهایی پاسخی درخور به موانع استفاده از زیرساخت‌های سنتی‌اش باشد. محصول ویژه «سرویس‌های مدیریت شده» و راه‌حل‌های ابری «زس» توانسته است جای خود را در پی‌ریزی تصمیمات تجاری و بخش IT این مجموعه باز کند تا فرآیندهای انطباق، انعطاف‌پذیری و مقیاس‌پذیری منابع IT دیگر برای‌شان دغدغه‌ای نباشد.        `,
        banner_alt: "نظر آژانس‌های خدماتی درباره ابر زس",
        banner_title: "نظر مشتری درباره خدمات ابر زس",
        logo_alt: "مپنا",
      },
    ],
  },
];

const dataBlog = [
  {
    img: "SLA.webp",
    link: "",
    type: "دسترسی سریع",
    title: "راهنمای سطوح ارائه خدمات ",
    desc: "کلیه مشتریان (حقیقی و حقوقی) ابر زس، می‌‌بایست از «شرایط و ضوابط استفاده از خدمات ابری» اطلاع داشته و شرایط مذکور را به ‌طور کامل مورد قبول و پذیرش خود قرار دهند. شرایط استفاده از خدمات در ابر زس مبتنی بر قوانین جمهوری اسلامی ایران می‌باشد",
    alt: "راهنمای سطوح ارائه خدمات سرویس ابری ابر زس",
  },
  {
    img: "Post-2.webp",
    link: "https://www.instagram.com/p/CgZvI1gjfV6/?igshid=MDJmNzVkMjY%3D",
    type: "اینستاگرام",
    title: "معرفی اپلیکشن‌های کاربردی - قسمت دوم",
    desc: "همه می دانیم که سهم گوشی های هوشمند، کامپیوترها و اینترنت در ساعات یک روز ما چقدر تعیین کننده است. با استفاده بهینه‌تر از این ابزارهای هوشمند، می‌توانیم اثربخشی بهتری را در عادت‌های خود و زمانی که برایشان می‌گذاریم تجربه کنیم.",
    alt: "معرفی اپلیکیشن‌های کاربردی",
  },

  {
    img: "VPSvsVDS.webp",
    link: "",
    type: "وبلاگ",
    title: "تفاوت VPS و VDS",
    desc: "صاحبان کسب‌وکارها همیشه به دنبال راهی برای افزایش زیرساخت و بستر مناسب برای گسترش خدمات و مشتریان خود هستند. یکی از کلیدی ترین زیرساخت های لازم، زیرساخت های فنی هستند. البته تامین این زیرساخت‌ها برای بسیاری از کسب‌وکارها به صرفه نبوده و گزینه استفاده از خدمات شرکت های ارائه دهنده خدمات ابری به وجود می‌آید. جایی که کسب‌وکارها را نیازمند می‌کند تا تفاوت VPS و VDS را به طور کامل بدانند.",
    alt: "تفاوت VPS و VDS",
  },
  {
    img: "Post-3.webp",
    link: "https://www.instagram.com/p/Cd3gU0ANXj1/?igshid=MDJmNzVkMjY=",
    type: "اینستاگرام",
    title: "دانستنی‌های کلود",
    desc: "رایانش ابر درباره نحوه انجام پردازش هاست، نه جایی که پردازش انجام می‌دهید». شاید مهم‌ترین مزیت استفاده از تکنولوژی ابری، عدم نیاز به حضور در مکانی خاص یا استفاده از ابزارهایی مشخص برای برقراری ارتباطات باشد.",
    alt: "دانستنی‌های سرویس ابری",
  },
  {
    img: "server-response-time.webp",
    link: "",
    type: "وبلاگ",
    title: "کاهش زمان پاسخگویی سرور",
    desc: "یکی از عواملی که بر تجربه کاربر از مراجعه به سایت تاثیرگذار است، مدت زمان بارگذاری سایت و پاسخ سرور است. با توجه به اینکه افراد برای انجام تمام امور روزمره خود از روش‌های آنلاین استفاده می‌کنند، عاملی که می‌تواند در این بازار مزیت اصلی برای رقابت محسوب شود، سرعت پاسخ‌دهی بالای یک سایت است. ابر زس در این مقاله سعی دارد عواملی که بر سرعت سایت تاثیرگذار هستند و راه‌های کاهش زمان پاسخگویی سرور را اطلاع دهد.",
    alt: "نحوه کاهش زمان پاسخگویی سرور در سرویس ابری",
  },
  {
    img: "Post-1.webp",
    link: "https://www.instagram.com/p/ChPvZ5ijiXh/?igshid=MDJmNzVkMjY%3D",
    type: "اینستاگرام",
    title: "معرفی بهترین دوره‌های آنلاین",
    desc: "با توجه به نتایج جستجو در گوگل، تصمیم‌گیری در مورد یک دوره خاص کار سختی است. اگر مایل به یادگیری کلود هستید و به تازگی «رایانش ابری» را در گوگل جستجو کرده‌اید از دیدن تعداد زیادی موسسه آموزشی که دوره‌هایی در این زمنه ارائه میددهند غافلگیر خواهید شد.",
    alt: "معرفی بهترین دوره‌های آنلاین رایانش ابری",
  },
  {
    img: "Cloud-Database.webp",
    link: "",
    type: "وبلاگ",
    title: "دیتابیس ابری چیست و چه کاربردی دارد",
    desc: "در سال‌های گذشته تکنولوژی ابری با استقبال بسیار زیادی مواجه شده‌است و از آن در زمینه‌های مختلف ایجاد سرور، گیمینگ و اجرای نرم افزارهای مختلف استفاده می‌شود. یکی از نمونه‌های استفاده از یارانش ابری، دیتابیس ابری است. در این مقاله به صورت ساده دیتاسنتر ابری را توضیح داده‌ایم و مزیت‌ها و چالش‌های آن را بیان کرده‌ایم. با ما همراه باشید.",
    alt: "دیتابیس ابری چیست؟",
  },
  {
    img: "Help.webp",
    link: "",
    type: "دسترسی سریع",
    title: "راهنمای محصولات ",
    desc: "با استفاده از راهنمای محصولات می توانید به راحتی شروع به استفاده از محصولات ابر زس نمایید و اگر در حین استفاده از خدمات ابر زس، سوالی داشتید به راحتی سوال خود را برطرف کنید.",
    alt: "راهنمای محصولات ابر زس",
  },
];
export default { tabItems, logos, customers, dataBlog };
